import config from "../config";

function validacaoValorBike(text) {
  let valorBike = 0;
  if (typeof text === 'string'){
    text =text.replace(/[^\d,.]/g, '');
    valorBike =  parseFloat(text.replace('.','').replace(',','.'));
  }else{
    valorBike = text
  }

  if (valorBike < config.BIKE_VALOR_MINIMO) {
    return `O seguro é feito para bikes acima de R$${config.BIKE_VALOR_MINIMO}`;
  }  else {
    return true;
  }
}

export default validacaoValorBike;
