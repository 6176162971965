import moment from 'moment';

function validacaoData(text) {
    
    var mDate = moment(text, "DD/MM/YYYY");

    if (!mDate.isValid()) {
        return 'Data inválida';
    }
    return true;
}

export default validacaoData;