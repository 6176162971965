import axios from "axios";
import auth from "./auth";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      auth.logout();
      window.location = "/";
    }
    
    return Promise.reject(error);
  }
);
