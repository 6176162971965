import config from "../config";

function validacaoValorAcessorio(text) {
  let valorAcessorio = 0;
  if (typeof text === 'string'){
    text =text.replace(/[^\d,.]/g, '');
    valorAcessorio =  parseFloat(text.replace('.','').replace(',','.'));
  }else{
    valorAcessorio = text
  }
 
  if (valorAcessorio < config.ACESSORIO_VALOR_MINIMO) {
    return `O seguro acessório é feito para acessório acima de R$${config.ACESSORIO_VALOR_MINIMO}`;
  } else if (valorAcessorio > config.ACESSORIO_VALOR_MAXIMO) {
    return `O seguro acessório é feito para acessório até R$${config.ACESSORIO_VALOR_MAXIMO}`;
  } else {
    return true;
  }
}

export default validacaoValorAcessorio;
