import moment from 'moment';

function validacaoExpiracaoCartaoCredito(texto) {

    if(!texto){ return false; }

    var partes = texto.split("/");
    var mes = partes[0];
    var ano = partes[1];      

    if (mes === undefined || ano === undefined) { return false; }

    var vMes = mes.trim();
    var vAno = ano.trim();
    if (vAno.length == 2) {
        vAno = "20" + vAno;
    }

    if (vMes.length == 1) {
        vMes = "0" + vMes;
    }

    var mExpireDate = moment(`${vAno}-${vMes}-01`);

    //Se passou da data de hoje: cartão vencido
    if (moment().isAfter(mExpireDate)) {
        return false;
    }

    return true;
}

function validacao(text) {

    var validado = validacaoExpiracaoCartaoCredito(text);
    if (!validado) {
        return "Cartão vencido. Por favor confira a data.";
    }
    return true;
}

export default validacao;