import Vue from "vue";
import VueRouter from "vue-router";
import config from "../config";
import { routes } from "./routes.js";
import auth from "../auth";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  //Registrando cada rota no Google Analytics
  if (window["gtag"] && config.ENABLE_MARKETING_TRACKERS) {
    window["gtag"]("config", config.GOOGLE_ANALYTICS_ID, {
      page_path: to.path,
    });
  }
  if (
    to.matched.some((record) => record.meta?.requiresAuth) &&
    !auth.isLoggedIn()
  ) {
    auth.logout();
    next({ path: "/", query: { returnUrl: to.fullPath } });
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
