import config from "../config";
import axios from "axios";
async function validacaoCupomDesconto(text) {
  if(text?.length<13){
    return "Cupom de desconto inválido!";
  }
  try {
    var response = await axios.get(
      `${config.API_URLV2}/cupom/BuscarCupom/${text}`
    );
    return response.data.ativo;
  } catch (error) {
    return "Cupom de desconto inválido!";
  }
}

export default validacaoCupomDesconto;
