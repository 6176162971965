const listaBancos = [
  { codigo: "1", nome: "Banco Do Brasil S.A (Bb)" },
  { codigo: "237", nome: "Bradesco S.A" },
  { codigo: "260", nome: "Nu Pagamentos S.A (Nubank)" },
  { codigo: "104", nome: "Caixa Econômica Federal (Cef)" },
  { codigo: "33", nome: "Banco Santander Brasil S.A" },
  { codigo: "77", nome: "Banco Inter S.A" },
  { codigo: "341", nome: "Itaú Unibanco S.A" },
  { codigo: "246", nome: "Banco ABC Brasil S.A." },
  { codigo: "748", nome: "Banco Cooperativo Sicredi S.A." },
  { codigo: "117", nome: "Advanced Cc Ltda" },
  { codigo: "121", nome: "Banco Agibank S.A." },
  { codigo: "172", nome: "Albatross Ccv S.A" },
  { codigo: "188", nome: "Ativa Investimentos S.A" },
  {
    codigo: "280",
    nome: "Avista S.A. Crédito, Financiamento e Investimento",
  },
  { codigo: "80", nome: "B&T Cc Ltda" },
  { codigo: "654", nome: "Banco A.J.Renner S.A." },
  { codigo: "75", nome: "Banco ABN AMRO S.A" },
  { codigo: "25", nome: "Banco Alfa S.A." },
  { codigo: "641", nome: "Banco Alvorada S.A." },
  { codigo: "65", nome: "Banco Andbank (Brasil) S.A." },
  { codigo: "213", nome: "Banco Arbi S.A." },
  { codigo: "96", nome: "Banco B3 S.A." },
  { codigo: "24", nome: "Banco BANDEPE S.A." },
  { codigo: "318", nome: "Banco BMG S.A." },
  { codigo: "752", nome: "Banco BNP Paribas Brasil S.A." },
  { codigo: "107", nome: "Banco BOCOM BBM S.A." },
  { codigo: "63", nome: "Banco Bradescard S.A." },
  { codigo: "36", nome: "Banco Bradesco BBI S.A." },
  { codigo: "122", nome: "Banco Bradesco BERJ S.A." },
  { codigo: "204", nome: "Banco Bradesco Cartões S.A." },
  { codigo: "394", nome: "Banco Bradesco Financiamentos S.A." },
  { codigo: "218", nome: "Banco BS2 S.A." },
  { codigo: "208", nome: "Banco BTG Pactual S.A." },
  { codigo: "336", nome: "Banco C6 S.A – C6 Bank" },
  { codigo: "473", nome: "Banco Caixa Geral – Brasil S.A." },
  { codigo: "412", nome: "Banco Capital S.A." },
  { codigo: "40", nome: "Banco Cargill S.A." },
  { codigo: "368", nome: "Banco Carrefour" },
  { codigo: "266", nome: "Banco Cédula S.A." },
  { codigo: "739", nome: "Banco Cetelem S.A." },
  { codigo: "233", nome: "Banco Cifra S.A." },
  { codigo: "745", nome: "Banco Citibank S.A." },
  { codigo: "241", nome: "Banco Clássico S.A." },
  { codigo: "756", nome: "Banco Cooperativo do Brasil S.A. – BANCOOB" },
  { codigo: "222", nome: "Banco Credit Agricole Brasil S.A." },
  { codigo: "505", nome: "Banco Credit Suisse (Brasil) S.A." },
  { codigo: "69", nome: "Banco Crefisa S.A." },
  { codigo: "3", nome: "Banco da Amazônia S.A." },
  { codigo: "83", nome: "Banco da China Brasil S.A." },
  { codigo: "707", nome: "Banco Daycoval S.A." },
  { codigo: "51", nome: "Banco de Desenvolvimento do Espírito Santo S.A." },
  { codigo: "300", nome: "Banco de La Nacion Argentina" },
  { codigo: "495", nome: "Banco de La Provincia de Buenos Aires" },
  { codigo: "494", nome: "Banco de La Republica Oriental del Uruguay" },
  { codigo: "335", nome: "Banco Digio S.A" },
  { codigo: "47", nome: "Banco do Estado de Sergipe S.A." },
  { codigo: "37", nome: "Banco do Estado do Pará S.A." },
  { codigo: "41", nome: "Banco do Estado do Rio Grande do Sul S.A." },
  { codigo: "4", nome: "Banco do Nordeste do Brasil S.A." },
  { codigo: "196", nome: "Banco Fair Corretora de Câmbio S.A" },
  { codigo: "265", nome: "Banco Fator S.A." },
  { codigo: "224", nome: "Banco Fibra S.A." },
  { codigo: "626", nome: "Banco Ficsa S.A." },
  { codigo: "94", nome: "Banco Finaxis S.A." },
  { codigo: "612", nome: "Banco Guanabara S.A." },
  { codigo: "12", nome: "Banco Inbursa S.A." },
  { codigo: "604", nome: "Banco Industrial do Brasil S.A." },
  { codigo: "653", nome: "Banco Indusval S.A." },
  { codigo: "249", nome: "Banco Investcred Unibanco S.A." },
  { codigo: "184", nome: "Banco Itaú BBA S.A." },
  { codigo: "29", nome: "Banco Itaú Consignado S.A." },
  { codigo: "479", nome: "Banco ItauBank S.A" },
  { codigo: "376", nome: "Banco J. P. Morgan S.A." },
  { codigo: "74", nome: "Banco J. Safra S.A." },
  { codigo: "217", nome: "Banco John Deere S.A." },
  { codigo: "76", nome: "Banco KDB S.A." },
  { codigo: "757", nome: "Banco KEB HANA do Brasil S.A." },
  { codigo: "600", nome: "Banco Luso Brasileiro S.A." },
  { codigo: "243", nome: "Banco Máxima S.A." },
  { codigo: "720", nome: "Banco Maxinvest S.A." },
  { codigo: "389", nome: "Banco Mercantil de Investimentos S.A." },
  { codigo: "370", nome: "Banco Mizuho do Brasil S.A." },
  { codigo: "746", nome: "Banco Modal S.A." },
  { codigo: "66", nome: "Banco Morgan Stanley S.A." },
  { codigo: "456", nome: "Banco MUFG Brasil S.A." },
  {
    codigo: "7",
    nome: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
  },
  { codigo: "169", nome: "Banco Olé Bonsucesso Consignado S.A." },
  { codigo: "111", nome: "Banco Oliveira Trust Dtvm S.A" },
  { codigo: "79", nome: "Banco Original do Agronegócio S.A." },
  { codigo: "212", nome: "Banco Original S.A." },
  { codigo: "712", nome: "Banco Ourinvest S.A." },
  { codigo: "623", nome: "Banco PAN S.A." },
  { codigo: "611", nome: "Banco Paulista S.A." },
  { codigo: "643", nome: "Banco Pine S.A." },
  { codigo: "658", nome: "Banco Porto Real de Investimentos S.A." },
  { codigo: "747", nome: "Banco Rabobank International Brasil S.A." },
  { codigo: "633", nome: "Banco Rendimento S.A." },
  { codigo: "741", nome: "Banco Ribeirão Preto S.A." },
  { codigo: "120", nome: "Banco Rodobens S.A." },
  { codigo: "422", nome: "Banco Safra S.A." },
  { codigo: "743", nome: "Banco Semear S.A." },
  { codigo: "754", nome: "Banco Sistema S.A." },
  { codigo: "630", nome: "Banco Smartbank S.A." },
  { codigo: "366", nome: "Banco Société Générale Brasil S.A." },
  { codigo: "637", nome: "Banco Sofisa S.A." },
  { codigo: "464", nome: "Banco Sumitomo Mitsui Brasileiro S.A." },
  { codigo: "82", nome: "Banco Topázio S.A." },
  { codigo: "634", nome: "Banco Triângulo S.A." },
  { codigo: "18", nome: "Banco Tricury S.A." },
  { codigo: "655", nome: "Banco Votorantim S.A." },
  { codigo: "610", nome: "Banco VR S.A." },
  { codigo: "119", nome: "Banco Western Union do Brasil S.A." },
  { codigo: "124", nome: "Banco Woori Bank do Brasil S.A." },
  { codigo: "348", nome: "Banco Xp S/A" },
  { codigo: "81", nome: "BancoSeguro S.A." },
  { codigo: "21", nome: "BANESTES S.A. Banco do Estado do Espírito Santo" },
  {
    codigo: "755",
    nome: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
  },
  { codigo: "268", nome: "Barigui Companhia Hipotecária" },
  { codigo: "250", nome: "BCV – Banco de Crédito e Varejo S.A." },
  { codigo: "144", nome: "BEXS Banco de Câmbio S.A." },
  { codigo: "253", nome: "Bexs Corretora de Câmbio S/A" },
  { codigo: "134", nome: "Bgc Liquidez Dtvm Ltda" },
  { codigo: "17", nome: "BNY Mellon Banco S.A." },
  { codigo: "301", nome: "Bpp Instituição De Pagamentos S.A" },
  { codigo: "126", nome: "BR Partners Banco de Investimento S.A." },
  { codigo: "70", nome: "BRB – Banco de Brasília S.A." },
  {
    codigo: "92",
    nome: "Brickell S.A. Crédito, Financiamento e Investimento",
  },
  {
    codigo: "173",
    nome: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  { codigo: "142", nome: "Broker Brasil Cc Ltda" },
  {
    codigo: "292",
    nome: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  { codigo: "11", nome: "C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)" },
  {
    codigo: "288",
    nome: "Carol Distribuidora de Títulos e Valor Mobiliários Ltda",
  },
  { codigo: "130", nome: "Caruana Scfi" },
  { codigo: "159", nome: "Casa Credito S.A" },
  { codigo: "16", nome: "Ccm Desp Trâns Sc E Rs" },
  { codigo: "89", nome: "Ccr Reg Mogiana" },
  {
    codigo: "114",
    nome: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
  },
  {
    codigo: "114-7",
    nome:
      "Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.",
  },
  {
    codigo: "320",
    nome: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
  },
  { codigo: "477", nome: "Citibank N.A." },
  { codigo: "180", nome: "Cm Capital Markets Cctvm Ltda" },
  { codigo: "127", nome: "Codepe Cvc S.A" },
  { codigo: "163", nome: "Commerzbank Brasil S.A. – Banco Múltiplo" },
  { codigo: "60", nome: "Confidence Cc S.A" },
  { codigo: "85", nome: "Coop Central Ailos" },
  {
    codigo: "97",
    nome: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda.",
  },
  { codigo: "085-x", nome: "Cooperativa Central de Crédito Urbano-CECRED" },
  {
    codigo: "090-2",
    nome: "Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS",
  },
  {
    codigo: "087-6",
    nome:
      "Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná",
  },
  {
    codigo: "089-2",
    nome: "Cooperativa de Crédito Rural da Região da Mogiana",
  },
  { codigo: "286", nome: "Cooperativa de Crédito Rural De Ouro" },
  {
    codigo: "279",
    nome: "Cooperativa de Crédito Rural de Primavera Do Leste",
  },
  {
    codigo: "273",
    nome:
      "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel",
  },
  { codigo: "98", nome: "Credialiança Ccr" },
  { codigo: "098-1", nome: "CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL" },
  { codigo: "10", nome: "Credicoamo" },
  { codigo: "133", nome: "Cresol Confederação" },
  { codigo: "182", nome: "Dacasa Financeira S/A" },
  { codigo: "487", nome: "Deutsche Bank S.A. – Banco Alemão" },
  { codigo: "140", nome: "Easynvest – Título Cv S.A" },
  { codigo: "149", nome: "Facta S.A. Cfi" },
  { codigo: "285", nome: "Frente Corretora de Câmbio Ltda." },
  {
    codigo: "278",
    nome: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
  },
  { codigo: "138", nome: "Get Money Cc Ltda" },
  { codigo: "64", nome: "Goldman Sachs do Brasil Banco Múltiplo S.A." },
  { codigo: "177", nome: "Guide Investimentos S.A. Corretora de Valores" },
  { codigo: "146", nome: "Guitta Corretora de Câmbio Ltda" },
  { codigo: "78", nome: "Haitong Banco de Investimento do Brasil S.A." },
  { codigo: "62", nome: "Hipercard Banco Múltiplo S.A." },
  {
    codigo: "189",
    nome: "HS Financeira S/A Crédito, Financiamento e Investimentos",
  },
  { codigo: "269", nome: "HSBC Brasil S.A. – Banco de Investimento" },
  {
    codigo: "271",
    nome: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  { codigo: "157", nome: "Icap Do Brasil Ctvm Ltda" },
  { codigo: "132", nome: "ICBC do Brasil Banco Múltiplo S.A." },
  { codigo: "492", nome: "ING Bank N.V." },
  { codigo: "139", nome: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo" },
  { codigo: "652", nome: "Itaú Unibanco Holding S.A." },
  { codigo: "488", nome: "JPMorgan Chase Bank, National Association" },
  { codigo: "399", nome: "Kirton Bank S.A. – Banco Múltiplo" },
  {
    codigo: "293",
    nome: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  { codigo: "105", nome: "Lecca Crédito, Financiamento e Investimento S/A" },
  { codigo: "145", nome: "Levycam Ccv Ltda" },
  { codigo: "113", nome: "Magliano S.A" },
  { codigo: "323", nome: "Mercado Pago – Conta Do Mercado Livre" },
  { codigo: "128", nome: "MS Bank S.A. Banco de Câmbio" },
  { codigo: "137", nome: "Multimoney Cc Ltda" },
  { codigo: "14", nome: "Natixis Brasil S.A. Banco Múltiplo" },
  {
    codigo: "191",
    nome: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  { codigo: "753", nome: "Novo Banco Continental S.A. – Banco Múltiplo" },
  { codigo: "613", nome: "Omni Banco S.A." },
  { codigo: "290", nome: "Pagseguro Internet S.A" },
  { codigo: "254", nome: "Paraná Banco S.A." },
  {
    codigo: "326",
    nome: "Parati – Crédito Financiamento e Investimento S.A.",
  },
  {
    codigo: "194",
    nome: "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda",
  },
  { codigo: "174", nome: "Pernambucanas Financ S.A" },
  { codigo: "100", nome: "Planner Corretora De Valores S.A" },
  { codigo: "125", nome: "Plural S.A. – Banco Múltiplo" },
  { codigo: "93", nome: "Pólocred Scmepp Ltda" },
  { codigo: "108", nome: "Portocred S.A" },
  { codigo: "283", nome: "Rb Capital Investimentos Dtvm Ltda" },
  { codigo: "101", nome: "Renascenca Dtvm Ltda" },
  { codigo: "270", nome: "Sagitur Corretora de Câmbio Ltda." },
  { codigo: "751", nome: "Scotiabank Brasil S.A. Banco Múltiplo" },
  {
    codigo: "276",
    nome: "Senff S.A. – Crédito, Financiamento e Investimento",
  },
  { codigo: "545", nome: "Senso Ccvm S.A" },
  { codigo: "190", nome: "Servicoop" },
  { codigo: "183", nome: "Socred S.A" },
  {
    codigo: "299",
    nome: "Sorocred Crédito, Financiamento e Investimento S.A.",
  },
  { codigo: "118", nome: "Standard Chartered Bank (Brasil) S/A–Bco Invest." },
  { codigo: "197", nome: "Stone Pagamentos S.A" },
  {
    codigo: "340",
    nome: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
  },
  { codigo: "95", nome: "Travelex Banco de Câmbio S.A." },
  { codigo: "143", nome: "Treviso Corretora de Câmbio S.A." },
  { codigo: "131", nome: "Tullett Prebon Brasil Cvc Ltda" },
  { codigo: "129", nome: "UBS Brasil Banco de Investimento S.A." },
  { codigo: "091-4", nome: "Unicred Central do Rio Grande do Sul" },
  { codigo: "91", nome: "Unicred Central Rs" },
  { codigo: "136", nome: "Unicred Cooperativa" },
  {
    codigo: "99",
    nome:
      "UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.",
  },
  {
    codigo: "84",
    nome:
      "Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências",
  },
  { codigo: "298", nome: "Vips Cc Ltda" },
  {
    codigo: "310",
    nome: "Vortx Distribuidora de Títulos e Valores Mobiliários Ltda",
  },
  { codigo: "102", nome: "Xp Investimentos S.A" },
];
export default listaBancos;
