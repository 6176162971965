import moment from 'moment';
const MAIORIDADE = 18;

function validacaoDataMaiorDeIdade(text) {

    var mDate = moment(text, "DD/MM/YYYY");
    var dataMinima = moment().startOf('day').add(-MAIORIDADE,'years');

    if (mDate.isAfter(dataMinima)) {
        return `Você precisa ter mais de ${MAIORIDADE} anos`;
    }

    return true;
}

export default validacaoDataMaiorDeIdade;