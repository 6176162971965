import * as Sentry from '@sentry/browser';

function captureError(error, email, cpf, nome) {
    Sentry.configureScope(scope => {
        if (error && error.config) {
            scope.setExtra("url", error.config.url);
            scope.setExtra("method", error.config.method);
            scope.setExtra("request_data", error.config.data);
        }

        if (error && error.response) {
            scope.setExtra("response_data", error.response.data);
            scope.setExtra("status", error.response.status);
            scope.setExtra("headers", error.response.headers);
        }

        if (error && error.message) {
            scope.setExtra("message", error.message);
        }

        if (email || nome) {
            scope.setUser({ email: email, username: nome })
        }

        if (cpf) {
            scope.setTag("cpf_cliente", cpf);
        }

        Sentry.captureException(error);
    });

}

export default captureError;