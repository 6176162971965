function validacaoTelefone(telefone) {   
    if(telefone.length<=14){
        return "Digite seu número completo"
    }
    var regex =/^\(?\d{2}\)?[-.\s]?(?:9\d{4}|\d{4})[-.\s]?\d{4}$/;
    let valor= regex.test(telefone);
    if(valor)
    {return true} 
    else 
    {return "Número inválido"} 
  }
  export default validacaoTelefone;