import moment from 'moment';

function validacaoDataFimDesconto(text) {
    
    var mDate = moment(text, "DD/MM/YYYY");

    if (!mDate.isValid()) {
        return 'Data inválida';
    }
    //Data futura
    if (mDate.isBefore(moment())) {
        return 'A data fim do desconto deve ser maior do que a data atual';
    }

    return true;
}

export default validacaoDataFimDesconto;