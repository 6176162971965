
function validacaoNomeCompleto(text) {

    if(!text){return false}
    
    var myArrayNoms = text.trim().split(" ");
    if(myArrayNoms[0].length<3){
        return "O primeiro nome deve ter no mínimo três letras"
    }
    if(myArrayNoms[1]?.length<2){
        return "O sobrenome deve ter no mínimo duas letras"
    }
    if(myArrayNoms.length<2){
        return "Digite seu nome completo"
    }
    return true;
}

export default validacaoNomeCompleto;