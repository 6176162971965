import moment from 'moment';

function validacaoDataNascimento(text, smalldate) {

    //Data inválida
    var mDate = moment(text, "DD/MM/YYYY");
    if (!mDate.isValid()) {
        return 'Data inválida';
    }

    //Data futura
    if (mDate.isAfter(moment())) {
        return 'A data não pode ser futura';
    }

    //Small datetime validation (1/1/1901)
    if (smalldate && mDate.isBefore(moment('1901-01-01'))) {
        return 'Data inválida';
    }

    return true;
}

export default validacaoDataNascimento;